<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div>
    <div class="flex">
      <div class="shrink-0">
        <div class="flow-root">
          <img src="~/assets/icons/icon-delivery-light.svg" alt="" class="h24 w28" loading="lazy">
        </div>
      </div>
      <div class="mt3 sm:ml3 sm:mt0">
        <h3 class="text-context fw600" n="sm slate9">
          {{ t('incentives.free_shipping.title') }}
        </h3>
        <p class="mt2 text-context" n="sm slate5">
          {{ t('incentives.free_shipping.description') }}
        </p>
      </div>
    </div>

    <div class="flex">
      <div class="shrink-0">
        <div class="flow-root">
          <img src="~/assets/icons/icon-chat-light.svg" alt="" class="h24 w28" loading="lazy">
        </div>
      </div>
      <div class="mt3 sm:ml3 sm:mt0">
        <h3 class="text-context fw600" n="sm slate9">
          {{ t('incentives.customer_support.title') }}
        </h3>
        <p class="mt2 text-context" n="sm slate5">
          {{ t('incentives.customer_support.description') }}
        </p>
      </div>
    </div>

    <div class="flex">
      <div class="shrink-0">
        <div class="flow-root">
          <img src="~/assets/icons/icon-fast-checkout-light.svg" alt="" class="h24 w28" loading="lazy">
        </div>
      </div>
      <div class="mt3 sm:ml3 sm:mt0">
        <h3 class="text-context fw600" n="sm slate9">
          {{ t('incentives.fast_shipment.title') }}
        </h3>
        <p class="mt2 text-context" n="sm slate5">
          {{ t('incentives.fast_shipment.description') }}
        </p>
      </div>
    </div>

    <div class="flex">
      <div class="shrink-0">
        <div class="flow-root">
          <img src="~/assets/icons/icon-gift-card-light.svg" alt="" class="h24 w28" loading="lazy">
        </div>
      </div>
      <div class="mt3 sm:ml3 sm:mt0">
        <h3 class="text-context fw600" n="sm slate9">
          {{ t('incentives.gift_with_every_purchase.title') }}
        </h3>
        <p class="mt2 text-context" n="sm slate5">
          {{ t('incentives.gift_with_every_purchase.description') }}
        </p>
      </div>
    </div>
  </div>
</template>
